.for-investors-page {
  background-color: #f5f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.for-investors-page .div {
  background-color: #ffffff;
  height: 2723px;
  position: relative;
  width: 1440px;
}

.for-investors-page .overlap {
  background-color: #f5f9fc;
  height: 2561px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.for-investors-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  height: 87px;
  left: 475px;
  position: absolute;
  top: 72px;
  width: 888px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

.for-investors-page .navbar {
  height: 35px;
  display: flex;
  align-items: center;
}

.for-investors-page .text-wrapper {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.for-investors-page .text-wrapper-2 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.for-investors-page .text-wrapper-3 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.for-investors-page .text-wrapper-4 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.for-investors-page .overlap-group-wrapper {
  height: 35px;
  width: 20%;
}

.for-investors-page .overlap-group {
  border: 1px solid;
  border-color: #53b3f8;
  border-radius: 7px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .text-wrapper-5 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.for-investors-page .logo {
  height: 70px;
  left: 102px;
  position: absolute;
  top: 86px;
  width: 225px;
}

.for-investors-page .overlap-2 {
  height: 714px;
  left: 90px;
  position: absolute;
  top: 553px;
  width: 1261px;
}

.for-investors-page .text {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 71.1px;
  font-weight: 400;
  left: 561px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  top: 0;
}

.for-investors-page .group-2 {
  height: 713px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1261px;
}

.for-investors-page .group-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 15px;
  height: 549px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 1261px;
}

.for-investors-page .group-3 {
  height: 485px;
  left: 77px;
  position: relative;
  top: 30px;
  width: 1143px;
}

.for-investors-page .overlap-3 {
  height: 412px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1105px;
}

.for-investors-page .frame {
  height: 393px;
  left: 28px;
  overflow: hidden;
  position: absolute;
  top: 10px;
  width: 1073px;
}

.for-investors-page .vector {
  height: 1px;
  left: 39px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1007px;
}

.for-investors-page .overlap-4 {
  height: 347px;
  left: 39px;
  position: absolute;
  top: 45px;
  width: 1007px;
}

.for-investors-page .overlap-group-2 {
  height: 343px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 1007px;
}

.for-investors-page .img {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 29px;
  width: 1007px;
}

.for-investors-page .vector-2 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 107px;
  width: 1007px;
}

.for-investors-page .vector-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 185px;
  width: 1007px;
}

.for-investors-page .vector-4 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 264px;
  width: 1007px;
}

.for-investors-page .vector-5 {
  height: 343px;
  left: 831px;
  position: absolute;
  top: 0;
  width: 60px;
}

.for-investors-page .vector-6 {
  height: 153px;
  left: 563px;
  position: absolute;
  top: 190px;
  width: 60px;
}

.for-investors-page .vector-7 {
  height: 52px;
  left: 296px;
  position: absolute;
  top: 291px;
  width: 60px;
}

.for-investors-page .vector-8 {
  height: 208px;
  left: 907px;
  position: absolute;
  top: 135px;
  width: 60px;
}

.for-investors-page .vector-9 {
  height: 88px;
  left: 640px;
  position: absolute;
  top: 255px;
  width: 60px;
}

.for-investors-page .vector-10 {
  height: 26px;
  left: 372px;
  position: absolute;
  top: 317px;
  width: 60px;
}

.for-investors-page .vector-11 {
  height: 304px;
  left: 95px;
  position: absolute;
  top: 15px;
  width: 806px;
}

.for-investors-page .text-wrapper-6 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 343px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 214px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-7 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 519px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 136px;
  white-space: nowrap;
}

.for-investors-page .vector-12 {
  height: 26px;
  left: 85px;
  position: absolute;
  top: 293px;
  width: 11px;
}

.for-investors-page .vector-13 {
  height: 26px;
  left: 352px;
  position: absolute;
  top: 239px;
  width: 11px;
}

.for-investors-page .vector-14 {
  height: 26px;
  left: 533px;
  position: absolute;
  top: 160px;
  width: 11px;
}

.for-investors-page .vector-15 {
  height: 8px;
  left: 28px;
  position: absolute;
  top: 340px;
  width: 60px;
}

.for-investors-page .vector-16 {
  height: 4px;
  left: 105px;
  position: absolute;
  top: 343px;
  width: 60px;
}

.for-investors-page .text-wrapper-8 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 78px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 275px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-9 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 914px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .vector-17 {
  height: 1px;
  left: 39px;
  object-fit: cover;
  position: absolute;
  top: 392px;
  width: 1007px;
}

.for-investors-page .vector-18 {
  height: 393px;
  left: -11111px;
  position: absolute;
  top: 117px;
  width: 1073px;
}

.for-investors-page .text-wrapper-10 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 392px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-11 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 329px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-12 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 10px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 311px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-13 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 235px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-14 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 157px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-15 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 79px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-16 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-17 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 271px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-18 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 201px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-19 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 126px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-20 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 59px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-21 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 1083px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .vector-19 {
  height: 10px;
  left: 185px;
  position: absolute;
  top: 471px;
  width: 60px;
}

.for-investors-page .vector-20 {
  height: 10px;
  left: 400px;
  position: absolute;
  top: 471px;
  width: 60px;
}

.for-investors-page .vector-21 {
  height: 5px;
  left: 751px;
  position: absolute;
  top: 474px;
  width: 60px;
}

.for-investors-page .text-wrapper-22 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 600;
  height: 20px;
  left: 142px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 414px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-23 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 600;
  height: 20px;
  left: 411px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 414px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-24 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 600;
  height: 20px;
  left: 680px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 414px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-25 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 600;
  height: 20px;
  left: 944px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 414px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-26 {
  color: #707070;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  left: 252px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 466px;
  white-space: nowrap;
}

.for-investors-page .text-wrapper-27 {
  color: #707070;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  left: 462px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 466px;
  width: 258px;
}

.for-investors-page .text-wrapper-28 {
  color: #707070;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  left: 814px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 466px;
  width: 130px;
}

.for-investors-page .group-4 {
  height: 126px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 565px;
}

.for-investors-page .text-wrapper-29 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 561px;
}

.for-investors-page .text-wrapper-30 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 32px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 106px;
  white-space: nowrap;
}

.for-investors-page .rectangle {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 73px;
}

.for-investors-page .group-5 {
  height: 373px;
  left: 103px;
  position: absolute;
  top: 1289px;
  width: 1262px;
}

.for-investors-page .group-6 {
  height: 344px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 46px;
  left: 21px;
  position: absolute;
  top: 0;
  width: 221px;
}

.for-investors-page .span-wrapper {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
}

.for-investors-page .span {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.for-investors-page .div-wrapper {
  background-color: #79c6fe;
  border-radius: 137px;
  height: 274px;
  left: 0;
  position: absolute;
  top: 70px;
  width: 274px;
}

.for-investors-page .text-wrapper-31 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48px;
  font-weight: bold;
  left: 78px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 109px;
  white-space: nowrap;
  width: 122px;
}

.for-investors-page .group-7 {
  height: 273px;
  left: 421px;
  position: absolute;
  top: 42px;
  width: 162px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .text-wrapper-32 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: 500;
  height: 20px;
  left: 45px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .overlap-5 {
  height: 148px;
  left: 0;
  position: absolute;
  top: 125px;
  width: 158px;
}

.for-investors-page .ellipse {
  background-color: #79c6fe;
  border-radius: 88px;
  height: 160px;
  left: 0;
  position: absolute;
  top: 0;
  width: 160px;
}

.for-investors-page .text-wrapper-33 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48px;
  font-weight: bold;
  left: 33px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 51px;
  white-space: nowrap;
  width: 125px;
}

.for-investors-page .group-8 {
  height: 298px;
  left: 722px;
  position: absolute;
  top: 42px;
  width: 202px;
}

.for-investors-page .text-wrapper-34 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: 500;
  height: 20px;
  left: 25px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .overlap-6 {
  background-color: #79c6fe;
  border-radius: 99px;
  height: 198px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 198px;
}

.for-investors-page .text-wrapper-35 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48px;
  font-weight: bold;
  left: 44px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 68px;
  white-space: nowrap;
  width: 113px;
}

.for-investors-page .group-9 {
  height: 315px;
  left: 975px;
  position: absolute;
  top: 0;
  width: 291px;
}

.for-investors-page .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 93px;
  left: 0;
  position: absolute;
  top: 0;
  width: 287px;
}

.for-investors-page .overlap-7 {
  background-color: #79c6fe;
  border-radius: 74px;
  height: 148px;
  left: 64px;
  position: absolute;
  top: 167px;
  width: 148px;
}

.for-investors-page .text-wrapper-36 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48px;
  font-weight: bold;
  left: 28px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 43px;
  white-space: nowrap;
  width: 106px;
}

.for-investors-page .overlap-8 {
  height: 376px;
  left: 102px;
  position: absolute;
  top: 1708px;
  width: 1158px;
}

.for-investors-page .text-2 {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 23.1px;
  font-weight: 500;
  height: 125px;
  left: 497px;
  letter-spacing: 0;
  line-height: 23.1px;
  position: absolute;
  top: 116px;
  width: 312px;
}

.for-investors-page .group-10 {
  height: 376px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1158px;
}

.for-investors-page .group-11 {
  height: 348px;
  left: 791px;
  position: absolute;
  top: 28px;
  width: 375px;
}

.for-investors-page .overlap-group-3 {
  background-image: url(../../../static/img/group-1000001776.png);
  background-size: 100% 100%;
  height: 348px;
  position: relative;
  width: 367px;
}

.for-investors-page .text-wrapper-37 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 28.3px;
  font-weight: 500;
  height: 19px;
  left: 63px;
  letter-spacing: 0;
  line-height: 17.7px;
  position: absolute;
  text-align: center;
  top: 151px;
  white-space: nowrap;
  width: 57px;
}

.for-investors-page .text-wrapper-38 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 28.3px;
  font-weight: 500;
  height: 19px;
  left: 215px;
  letter-spacing: 0;
  line-height: 17.7px;
  position: absolute;
  text-align: center;
  top: 242px;
  white-space: nowrap;
  width: 57px;
}

.for-investors-page .text-wrapper-39 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 28.3px;
  font-weight: 500;
  height: 19px;
  left: 248px;
  letter-spacing: 0;
  line-height: 17.7px;
  position: absolute;
  text-align: center;
  top: 117px;
  white-space: nowrap;
  width: 56px;
}

.for-investors-page .text-wrapper-40 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 28.3px;
  font-weight: 500;
  height: 19px;
  left: 184px;
  letter-spacing: 0;
  line-height: 17.7px;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
  width: 40px;
}

.for-investors-page .group-12 {
  height: 365px;
  left: 0;
  position: absolute;
  top: 0;
  width: 637px;
}

.for-investors-page .group-13 {
  height: 147px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 319px;
}

.for-investors-page .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 19px;
  height: 147px;
  left: 60px;
  position: absolute;
  top: 9px;
  width: 257px;
}

.for-investors-page .p {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
}

.for-investors-page .text-wrapper-41 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.for-investors-page .rectangle-2 {
  background-color: #faae6e;
  height: 13px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 47px;
}

.for-investors-page .rectangle-3 {
  background-color: #f97267;
  height: 13px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 47px;
}

.for-investors-page .rectangle-4 {
  background-color: #79c6fe;
  height: 13px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 47px;
}

.for-investors-page .rectangle-5 {
  background-color: #4680f1;
  height: 13px;
  left: 0;
  position: absolute;
  top: 124px;
  width: 47px;
}

.for-investors-page .text-wrapper-42 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 553px;
}

.for-investors-page .we-are-seeking-for {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 99px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 102px;
  width: 633px;
}

.for-investors-page .rectangle-6 {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 81px;
  width: 73px;
}

.for-investors-page .overlap-wrapper {
  height: 284px;
  left: 90px;
  position: absolute;
  top: 230px;
  width: 1263px;
}

.for-investors-page .flexcontainer-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 15px;
  height: 284px;
  width: 1261px;
}

.for-investors-page .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 233px;
  left: 58px;
  position: relative;
  top: 23px;
  width: 1129px;
}

.for-investors-page .text-3 {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
}

.for-investors-page .text-wrapper-43 {
  font-weight: bold;
}

.for-investors-page .text-wrapper-44 {
  font-family: "FiraGO", "Fira Sans";
  font-weight: 300;
}

.for-investors-page .group-14 {
  height: 365px;
  left: 86px;
  position: absolute;
  top: 2150px;
  width: 1278px;
}

.for-investors-page .overlap-9 {
  background-color: #ffffff;
  border-radius: 10.6px;
  box-shadow: 0px 0px 63.6px 31.8px #00000008;
  height: 365px;
  position: relative;
  width: 1268px;
}

.for-investors-page .frame-2 {
  background-image: url(../../../static/img/rectangle-3841.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 337px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 14px;
  width: 520px;
}

.for-investors-page .overlap-group-4 {
  height: 313px;
  left: 300px;
  position: absolute;
  top: 464px;
  width: 315px;
}

.for-investors-page .ellipse-2 {
  background-color: #95c9ffb2;
  border-radius: 142.57px;
  height: 285px;
  left: 30px;
  position: absolute;
  top: 28px;
  width: 285px;
}

.for-investors-page .ellipse-3 {
  background-color: #1e81ce4c;
  border-radius: 73.14px;
  height: 146px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
}

.for-investors-page .overlap-10 {
  height: 34px;
  left: 57px;
  position: absolute;
  top: 229px;
  width: 305px;
}

.for-investors-page .img-2 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.for-investors-page .group-15 {
  height: 34px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 302px;
}

.for-investors-page .vector-22 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 15px;
}

.for-investors-page .element-six-forks-rd {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14.4px;
  font-weight: 400;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 259px;
}

.for-investors-page .group-16 {
  height: 22px;
  left: 57px;
  position: absolute;
  top: 165px;
  width: 133px;
}

.for-investors-page .text-wrapper-45 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14.4px;
  font-weight: 400;
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.for-investors-page .group-17 {
  height: 22px;
  left: 57px;
  position: absolute;
  top: 194px;
  width: 238px;
}

.for-investors-page .text-wrapper-46 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 32px;
  font-weight: 600;
  left: 57px;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 46px;
  width: 181px;
}

.for-investors-page .text-wrapper-47 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 600;
  left: 57px;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 124px;
  width: 194px;
}

.for-investors-page .overlap-11 {
  background-color: #f97267;
  border-radius: 12px;
  height: 30px;
  left: 1110px;
  position: absolute;
  top: 312px;
  width: 105px;
  border: none;
}

.for-investors-page .text-wrapper-48 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 6px;
}

.for-investors-page .overlap-12 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 12px;
  height: 30px;
  left: 780px;
  position: absolute;
  top: 34px;
  width: 435px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .text-wrapper-49 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 12px;
  font-weight: 300;
  height: 12px;
  left: 21px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
  width: 64px;
}

.for-investors-page .overlap-13 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 12px;
  height: 30px;
  left: 780px;
  position: absolute;
  top: 78px;
  width: 435px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .overlap-14 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 12px;
  height: 30px;
  left: 780px;
  position: absolute;
  top: 125px;
  width: 435px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .text-wrapper-50 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 12px;
  font-weight: 300;
  height: 12px;
  left: 21px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
  width: 91px;
}

.for-investors-page .overlap-15 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 12px;
  height: 125px;
  left: 780px;
  position: absolute;
  top: 173px;
  width: 435px;
  display: flex;
  align-items: left;
  justify-content: left;
}

.for-investors-page .overlap-16 {
  height: 3006px;
  left: -8814px;
  position: absolute;
  top: 978px;
  width: 2680px;
}

.for-investors-page .rectangle-7 {
  height: 2299px;
  left: 1054px;
  position: absolute;
  top: 0;
  width: 1626px;
}

.for-investors-page .rectangle-8 {
  height: 2076px;
  left: 0;
  position: absolute;
  top: 930px;
  width: 1468px;
}

.for-investors-page .group-18 {
  height: 162px;
  left: 0;
  position: absolute;
  top: 2561px;
  width: 1442px;
}

.for-investors-page .overlap-17 {
  background-color: #595959;
  height: 162px;
  position: relative;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for-investors-page .text-wrapper-51 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.for-investors-page .group-19 {
  height: 26px;
  left: 358px;
  position: absolute;
  top: 87px;
  width: 213px;
}

.for-investors-page .text-wrapper-52 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 189px;
}

.for-investors-page .vector-23 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.for-investors-page .group-20 {
  height: 22px;
  left: 937px;
  position: absolute;
  top: 90px;
  width: 149px;
}

.for-investors-page .text-wrapper-53 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 118px;
}

.for-investors-page .vector-24 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.for-investors-page .group-21 {
  height: 23px;
  left: 608px;
  position: absolute;
  top: 88px;
  width: 118px;
}

.for-investors-page .text-wrapper-54 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 91px;
}

.for-investors-page .group-22 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.for-investors-page .group-23 {
  height: 22px;
  left: 771px;
  position: absolute;
  top: 90px;
  width: 133px;
}

.for-investors-page .text-wrapper-55 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 99px;
}

.for-investors-page .vector-25 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.for-investors-page .rectangle-9 {
  background-color: #ffffff;
  height: 18px;
  left: 588px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.for-investors-page .rectangle-10 {
  background-color: #ffffff;
  height: 18px;
  left: 746px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.for-investors-page .rectangle-11 {
  background-color: #ffffff;
  height: 18px;
  left: 920px;
  position: absolute;
  top: 91px;
  width: 1px;
}


.for-investors-page .overlap-12 input,
.for-investors-page .overlap-13 input,
.for-investors-page .overlap-14 input,
.for-investors-page .overlap-15 textarea {
  font-family: FiraGO, Helvetica;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0px; /* Add margin-top to create space */
  border: none;
  outline: none;
  resize: none;
  background: rgba(255, 255, 255, 0.8); /* Set a semi-transparent background */
}

.for-investors-page .overlap-15 textarea {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Add this to your existing CSS */
.for-investors-page .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.for-investors-page .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: opacity 0.5s;
}

.for-investors-page .popup.success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.for-investors-page .popup.error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
