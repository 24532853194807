.services-page {
  background-color: #f5f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.services-page .div {
  height: 1976px;
  position: relative;
  width: 1440px;
}

/*.services-page .overlap {
  background-image: url(../../../static/img/rectangle-65.svg);
  background-size: 100% 100%;
  height: 1814px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}*/

.services-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  height: 87px;
  left: 475px;
  position: absolute;
  top: 72px;
  width: 888px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

.services-page .navbar {
  height: 35px;
  display: flex;
  align-items: center;
}

.services-page .text-wrapper {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.services-page .text-wrapper-2 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.services-page .text-wrapper-3 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.services-page .text-wrapper-4 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.services-page .overlap-group-wrapper {
  height: 35px;
  width: 20%;
}

.services-page .overlap-group {
  border: 1px solid;
  border-color: #53b3f8;
  border-radius: 7px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-page .text-wrapper-5 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.services-page .overlap-2 {
  height: 459px;
  left: 102px;
  position: absolute;
  top: 205px;
  width: 1288px;
}

.services-page .text {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 71.1px;
  font-weight: 400;
  left: 549px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  top: 219px;
}

.services-page .group-2 {
  height: 459px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1288px;
}

.services-page .group-3 {
  height: 251px;
  left: 0;
  position: absolute;
  top: 116px;
  width: 572px;
}

.services-page .text-wrapper-6 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 373px;
}

.services-page .overlap-group-2 {
  height: 176px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 568px;
}

.services-page .unlock-the-potential {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 176px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 40px;
  width: 568px;
}

.services-page .rectangle {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 73px;
}

.services-page .mockup-kiu {
  height: 459px;
  left: 614px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 674px;
}

.services-page .logo {
  height: 70px;
  left: 102px;
  position: absolute;
  top: 86px;
  width: 225px;
}

.services-page .group-4 {
  height: 474px;
  left: 102px;
  position: absolute;
  top: 1266px;
  width: 1245px;
}

.services-page .group-5 {
  height: 383px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1245px;
}

.services-page .project-management {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  width: 558px;
}

.services-page .rectangle-2 {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 141px;
  width: 73px;
}

.services-page .group-6 {
  height: 198px;
  left: 0;
  position: absolute;
  top: 185px;
  width: 1243px;
}

.services-page .div-wrapper {
  height: 193px;
  left: 0;
  position: absolute;
  top: 0;
  width: 394px;
}

.services-page .overlap-group-3 {
  background-color: #41cbe0;
  border-radius: 13px;
  height: 193px;
  position: relative;
  width: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 50px;
  left: 40px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 40px;
}

.services-page .p {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 123px;
  left: 40px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 80px;
  width: 310px;
}

.services-page .overlap-wrapper {
  height: 193px;
  left: 426px;
  position: absolute;
  top: 0;
  width: 394px;
}

.services-page .overlap-3 {
  background-color: #79c6fe;
  border-radius: 13px;
  height: 193px;
  position: relative;
  width: 390px;
}

.services-page .text-wrapper-8 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 49px;
  left: 41px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 40px;
  width: 270px;
}

.services-page .text-wrapper-9 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 123px;
  left: 41px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 80px;
  width: 310px;
}

.services-page .group-7 {
  height: 198px;
  left: 853px;
  position: absolute;
  top: 0;
  width: 394px;
}

.services-page .overlap-4 {
  height: 198px;
  position: relative;
  width: 390px;
}

.services-page .rectangle-3 {
  background-color: #67a3ec;
  border: 0px none;
  border-radius: 13px;
  height: 193px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}

.services-page .text-wrapper-10 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 42px;
  left: 48px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 40px;
  width: 270px;
}

.services-page .text-wrapper-11 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 116px;
  left: 48px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 80px;
  width: 310px;
}

.services-page .text-wrapper-12 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 63px;
  left: 339px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 422px;
  width: 571px;
}

.services-page .group-8 {
  height: 473px;
  left: 57px;
  position: absolute;
  top: 736px;
  width: 1277px;
}

.services-page .group-9 {
  height: 325px;
  left: 725px;
  position: absolute;
  top: 73px;
  width: 556px;
}

.services-page .text-wrapper-13 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
}

.services-page .QA-validation {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 232px;
  left: 8px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 110px;
  width: 544px;
}

.services-page .rectangle-4 {
  background-color: #f97267;
  height: 4px;
  left: 8px;
  position: absolute;
  top: 77px;
  width: 73px;
}

.services-page .img {
  height: 473px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 695px;
}

.services-page .group-10 {
  height: 162px;
  position: absolute;
  top: 1814px;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-page .overlap-5 {
  background-color: #595959;
  height: 162px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-page .text-wrapper-14 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.services-page .group-11 {
  height: 26px;
  left: 358px;
  position: absolute;
  top: 87px;
  width: 213px;
}

.services-page .text-wrapper-15 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 189px;
}

.services-page .vector {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.services-page .group-12 {
  height: 22px;
  left: 937px;
  position: absolute;
  top: 90px;
  width: 149px;
}

.services-page .text-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 118px;
}

.services-page .vector-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.services-page .group-13 {
  height: 23px;
  left: 608px;
  position: absolute;
  top: 88px;
  width: 118px;
}

.services-page .text-wrapper-17 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 91px;
}

.services-page .group-14 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.services-page .group-15 {
  height: 22px;
  left: 771px;
  position: absolute;
  top: 90px;
  width: 133px;
}

.services-page .text-wrapper-18 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 99px;
}

.services-page .vector-3 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.services-page .rectangle-5 {
  background-color: #ffffff;
  height: 18px;
  left: 588px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.services-page .rectangle-6 {
  background-color: #ffffff;
  height: 18px;
  left: 746px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.services-page .rectangle-7 {
  background-color: #ffffff;
  height: 18px;
  left: 920px;
  position: absolute;
  top: 92px;
  width: 1px;
}
