.contact-us-page {
  background-color: #f5f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.contact-us-page .div {
  background-color: #ffffff;
  height: 1476px;
  position: relative;
  width: 1440px;
}

.contact-us-page .overlap {
  background-image: url(../../../static/img/rectangle-88.svg);
  background-size: 100% 100%;
  height: 1314px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.contact-us-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  height: 87px;
  left: 475px;
  position: absolute;
  top: 72px;
  width: 888px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

.contact-us-page .navbar {
  height: 35px;
  display: flex;
  align-items: center;
}

.contact-us-page .text-wrapper {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.contact-us-page .text-wrapper-2 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.contact-us-page .text-wrapper-3 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.contact-us-page .text-wrapper-4 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.contact-us-page .overlap-group-wrapper {
  height: 35px;
  width: 20%;
}

.contact-us-page .overlap-group {
  border: 1px solid;
  border-color: #53b3f8;
  border-radius: 7px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-page .text-wrapper-5 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.contact-us-page .logo {
  height: 70px;
  left: 102px;
  position: absolute;
  top: 86px;
  width: 225px;
}

.contact-us-page .group-2 {
  height: 890px;
  left: 89px;
  position: absolute;
  top: 268px;
  width: 1268px;
}

.contact-us-page .overlap-wrapper {
  height: 707px;
  left: 0;
  position: absolute;
  top: 183px;
  width: 1270px;
}

.contact-us-page .overlap-2 {
  height: 707px;
  position: relative;
  width: 1268px;
}

.contact-us-page .text {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 71.1px;
  font-weight: 400;
  left: 562px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  top: 158px;
}

.contact-us-page .div-wrapper {
  height: 707px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1268px;
}

.contact-us-page .overlap-3 {
  height: 707px;
  position: relative;
}

.contact-us-page .overlap-4 {
  border-radius: 10.6px;
  height: 707px;
  position: relative;
}

.contact-us-page .frame-wrapper {
  background-color: #ffffff;
  border-radius: 10.6px;
  box-shadow: 0px 0px 63.6px 31.8px #00000008;
  height: 707px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1268px;
}

.contact-us-page .frame {
  background-image: url(../../../static/img/rectangle-3841.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 686px;
  left: 11px;
  overflow: hidden;
  position: relative;
  top: 11px;
  width: 520px;
}

.contact-us-page .overlap-group-2 {
  height: 313px;
  left: 300px;
  position: absolute;
  top: 464px;
  width: 315px;
}

.contact-us-page .ellipse {
  background-color: #95c9ffb2;
  border-radius: 142.57px;
  height: 285px;
  left: 30px;
  position: absolute;
  top: 28px;
  width: 285px;
}

.contact-us-page .ellipse-2 {
  background-color: #1e81ce4c;
  border-radius: 73.14px;
  height: 146px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
}

.contact-us-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 29.7px;
  font-weight: 600;
  left: 42px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 41px;
}

.contact-us-page .group-3 {
  height: 197px;
  left: 42px;
  position: absolute;
  top: 161px;
  width: 359px;
}

.contact-us-page .carbon-location {
  height: 25px;
  left: 0;
  position: absolute;
  top: 157px;
  width: 25px;
}

.contact-us-page .group-4 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 157px;
}

.contact-us-page .img {
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}

.contact-us-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 17px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.contact-us-page .group-5 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 78px;
  width: 281px;
}

.contact-us-page .element-six-forks-rd {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 17px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 157px;
  width: 305px;
}

.contact-us-page .group-6 {
  height: 58px;
  left: 584px;
  position: absolute;
  top: 64px;
  width: 297px;
}

.contact-us-page .vector {
  height: 3px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 295px;
}

.contact-us-page .vector-2 {
  height: 12px;
  left: 5px;
  position: absolute;
  top: 34px;
  width: 1px;
}

.contact-us-page .text-wrapper-8 {
  color: #8d8d8d;
  font-family: "FiraGO", "Fira Sans";
  font-size: 15px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.contact-us-page .group-7 {
  height: 58px;
  left: 584px;
  position: absolute;
  top: 170px;
  width: 297px;
}

.contact-us-page .group-8 {
  height: 58px;
  left: 920px;
  position: absolute;
  top: 64px;
  width: 299px;
}

.contact-us-page .text-wrapper-9 {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 12.7px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.contact-us-page .text-wrapper-10 {
  color: #8d8d8d;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14.8px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.contact-us-page .group-9 {
  height: 59px;
  left: 584px;
  position: absolute;
  top: 329px;
  width: 635px;
}

.contact-us-page .vector-3 {
  height: 3px;
  left: 0;
  position: absolute;
  top: 65px;
  width: 631px;
}

.contact-us-page .text-wrapper-11 {
  color: #8d8d8d;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14.8px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 27px;
  white-space: nowrap;
  width: 158px;
}

.contact-us-page .group-10 {
  height: 39px;
  left: 998px;
  position: absolute;
  top: 413px;
  background-color: #f97267;
  border-radius: 9px;
  width: 216px;
  border: none;
}
/*
.contact-us-page .overlap-5 {
  background-color: #f97267;
  border-radius: 7.99px;
  height: 39px;
  position: relative;
  width: 216px;
}*/

.contact-us-page .text-wrapper-12 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 18.4px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.contact-us-page .frame-2 {
  height: 23px;
  left: 67px;
  position: absolute;
  top: 622px;
  width: 120px;
}

.contact-us-page .group-11 {
  height: 123px;
  left: 16px;
  position: absolute;
  top: 0;
  width: 377px;
}

.contact-us-page .p {
  color: #707070;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 104px;
  white-space: nowrap;
}

.contact-us-page .text-wrapper-13 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 373px;
}

.contact-us-page .rectangle {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 73px;
}

.contact-us-page .overlap-6 {
  height: 2076px;
  left: -7578px;
  position: absolute;
  top: 826px;
  width: 1548px;
}

.contact-us-page .rectangle-2 {
  height: 1492px;
  left: 80px;
  position: absolute;
  top: 34px;
  width: 1468px;
}

.contact-us-page .rectangle-3 {
  height: 2076px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1468px;
}

.contact-us-page .group-12 {
  height: 162px;
  left: 0;
  position: absolute;
  top: 1314px;
  width: 1442px;
}

.contact-us-page .overlap-7 {
  background-color: #595959;
  height: 162px;
  position: relative;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-page .text-wrapper-14 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.contact-us-page .group-13 {
  height: 26px;
  left: 358px;
  position: absolute;
  top: 87px;
  width: 213px;
}

.contact-us-page .text-wrapper-15 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 189px;
}

.contact-us-page .vector-4 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.contact-us-page .group-14 {
  height: 22px;
  left: 937px;
  position: absolute;
  top: 90px;
  width: 149px;
}

.contact-us-page .text-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 118px;
}

.contact-us-page .vector-5 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.contact-us-page .group-15 {
  height: 23px;
  left: 608px;
  position: absolute;
  top: 88px;
  width: 118px;
}

.contact-us-page .text-wrapper-17 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 91px;
}

.contact-us-page .group-16 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.contact-us-page .group-17 {
  height: 22px;
  left: 771px;
  position: absolute;
  top: 90px;
  width: 133px;
}

.contact-us-page .text-wrapper-18 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 99px;
}

.contact-us-page .vector-6 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.contact-us-page .rectangle-4 {
  background-color: #ffffff;
  height: 18px;
  left: 588px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.contact-us-page .rectangle-5 {
  background-color: #ffffff;
  height: 18px;
  left: 746px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.contact-us-page .rectangle-6 {
  background-color: #ffffff;
  height: 18px;
  left: 920px;
  position: absolute;
  top: 91px;
  width: 1px;
}







.contact-us-page .group-6 input,
.contact-us-page .group-7 input,
.contact-us-page .group-8 input,
.contact-us-page .group-9 textarea {
  font-family: FiraGO, Helvetica;
  width: 98%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 18px; /* Add margin-top to create space */
  border: none;
  outline: none;
  resize: none;
}


/* Add this to your existing CSS */
.contact-us-page .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.contact-us-page .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: opacity 0.5s;
}

.contact-us-page .popup.success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.contact-us-page .popup.error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
