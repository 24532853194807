.about-us-page {
  background-color: #f5f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.about-us-page .div {
  height: 1476px;
  position: relative;
  width: 1440px;
}

/*.about-us-page .overlap {
  background-image: url(../../../static/img/rectangle-74.svg);
  background-size: 100% 100%;
  height: 1314px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}*/

.about-us-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  height: 87px;
  left: 475px;
  position: absolute;
  top: 72px;
  width: 888px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

.about-us-page .navbar {
  height: 35px;
  display: flex;
  align-items: center;
}

.about-us-page .text-wrapper {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.about-us-page .text-wrapper-2 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.about-us-page .text-wrapper-3 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.about-us-page .text-wrapper-4 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.about-us-page .overlap-group-wrapper {
  height: 35px;
  width: 20%;
}

.about-us-page .overlap-group {
  border: 1px solid;
  border-color: #53b3f8;
  border-radius: 7px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-page .text-wrapper-5 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.about-us-page .overlap-2 {
  height: 347px;
  left: 112px;
  position: absolute;
  top: 278px;
  width: 845px;
}

.about-us-page .text {
  color: #000000;
  font-family: "FiraGO", "Fira Sans";
  font-size: 71.1px;
  font-weight: 400;
  left: 539px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  top: 146px;
}

.about-us-page .group-2 {
  height: 347px;
  left: 0;
  position: absolute;
  top: 0;
  width: 845px;
}

.about-us-page .group-3 {
  height: 295px;
  left: 363px;
  position: absolute;
  top: 0;
  width: 486px;
}

.about-us-page .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 177px;
  left: 0;
  position: absolute;
  top: 118px;
  width: 482px;
}

.about-us-page .span-wrapper {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.about-us-page .span {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.about-us-page .text-wrapper-6 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 345px;
}

.about-us-page .rectangle {
  background-color: #f97267;
  height: 4px;
  left: 0;
  position: absolute;
  top: 84px;
  width: 73px;
}

.about-us-page .img {
  height: 20px;
  left: 363px;
  position: absolute;
  top: 327px;
  width: 102px;
}

.about-us-page .group-4 {
  background-image: url(../../../static/img/clip-path-group-1.png);
  background-size: 100% 100%;
  height: 297px;
  left: 0;
  position: absolute;
  top: 0;
  width: 297px;
}

.about-us-page .logo {
  height: 70px;
  left: 102px;
  position: absolute;
  top: 86px;
  width: 225px;
}

.about-us-page .group-5 {
  height: 389px;
  left: 454px;
  position: absolute;
  top: 728px;
  width: 866px;
}

.about-us-page .group-6 {
  height: 329px;
  left: 0;
  position: absolute;
  top: 0;
  width: 544px;
}

.about-us-page .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 209px;
  left: 0;
  position: absolute;
  top: 120px;
  width: 510px;
}

.about-us-page .p {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: right;
}

.about-us-page .text-wrapper-7 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: bold;
  left: 232px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 308px;
}

.about-us-page .rectangle-2 {
  background-color: #f97267;
  height: 4px;
  left: 437px;
  position: absolute;
  top: 85px;
  width: 73px;
}

.about-us-page .group-7 {
  height: 20px;
  left: 408px;
  position: absolute;
  top: 369px;
  width: 102px;
}

.about-us-page .group-8 {
  background-image: url(../../../static/img/clip-path-group.png);
  background-size: 100% 100%;
  height: 297px;
  left: 569px;
  position: absolute;
  top: 16px;
  width: 297px;
}

.about-us-page .overlap-3 {
  height: 2076px;
  left: -5866px;
  position: absolute;
  top: 826px;
  width: 1507px;
}

.about-us-page .rectangle-3 {
  height: 1492px;
  left: 39px;
  position: absolute;
  top: 34px;
  width: 1468px;
}

.about-us-page .rectangle-4 {
  height: 2076px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1468px;
}

.about-us-page .overlap-wrapper {
  height: 162px;
  position: absolute;
  top: 1314px;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-page .overlap-4 {
  background-color: #595959;
  height: 162px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-page .text-wrapper-8 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.about-us-page .group-9 {
  height: 26px;
  left: 358px;
  position: absolute;
  top: 87px;
  width: 213px;
}

.about-us-page .text-wrapper-9 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 189px;
}

.about-us-page .vector {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.about-us-page .group-10 {
  height: 22px;
  left: 937px;
  position: absolute;
  top: 90px;
  width: 149px;
}

.about-us-page .text-wrapper-10 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 118px;
}

.about-us-page .vector-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.about-us-page .group-11 {
  height: 23px;
  left: 608px;
  position: absolute;
  top: 88px;
  width: 118px;
}

.about-us-page .text-wrapper-11 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 91px;
}

.about-us-page .group-12 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.about-us-page .group-13 {
  height: 22px;
  left: 771px;
  position: absolute;
  top: 90px;
  width: 133px;
}

.about-us-page .text-wrapper-12 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 99px;
}

.about-us-page .vector-3 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.about-us-page .rectangle-5 {
  background-color: #ffffff;
  height: 18px;
  left: 588px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.about-us-page .rectangle-6 {
  background-color: #ffffff;
  height: 18px;
  left: 746px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.about-us-page .rectangle-7 {
  background-color: #ffffff;
  height: 18px;
  left: 920px;
  position: absolute;
  top: 91px;
  width: 1px;
}
