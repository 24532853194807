body {
  background-color: #f5f9fc; /* Replace with your desired color value */
  margin: 0; /* Optional: Remove default body margin */
  padding: 0; /* Optional: Remove default body padding */
}

.product-page {
  background-color: #f5f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.product-page .div {
  height: 3062px;
  position: relative;
  width: 1440px;
}

/*.product-page .overlap {
  background-color: #f5f9fc;
  height: 2904px;
  left: 0;
  position: relative;
  overflow: scroll;
  top: 0;
  width: 100%;
}*/

.product-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  height: 87px;
  left: 475px;
  position: absolute;
  top: 72px;
  width: 888px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

.product-page .navbar {
  height: 35px;
  display: flex;
  align-items: center;
}

.product-page .text-wrapper {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.product-page .text-wrapper-2 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.product-page .text-wrapper-3 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.product-page .text-wrapper-4 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-left: auto;
  margin: 0 33px;
}

.product-page .overlap-group-wrapper {
  height: 35px;
  width: 20%;
}

.product-page .overlap-group {
  border: 1px solid;
  border-color: #53b3f8;
  border-radius: 7px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-5 {
  color: #fff1f1;
  font-family: "FiraGO", "Fira Sans";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 33px;
}

.product-page .group-2 {
  height: 718px;
  left: 123px;
  position: absolute;
  top: 1135px;
  width: 1208px;
}

.product-page .div-wrapper {
  height: 63px;
  left: 294px;
  position: absolute;
  top: 0;
  width: 601px;
}

.product-page .overlap-group-2 {
  background-color: #41cbe0;
  border-radius: 13px;
  height: 63px;
  position: relative;
  width: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  white-space: nowrap;
}

.product-page .overlap-wrapper {
  height: 610px;
  left: 0;
  position: absolute;
  top: 108px;
  width: 1208px;
}

.product-page .overlap-2 {
  height: 610px;
  position: relative;
}

.product-page .group-3 {
  height: 113px;
  left: 149px;
  position: absolute;
  top: 237px;
  width: 489px;
}

.product-page .text-wrapper-7 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 20px;
  left: 169px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.product-page .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 57px;
  left: 169px;
  position: absolute;
  top: 50px;
  width: 316px;
}

.product-page .text {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.product-page .span {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.product-page .span-wrapper {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -3px;
  position: relative;
}

.product-page .frame-11 {
  height: 113px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
}

.product-page .group-4 {
  height: 131px;
  left: 0;
  position: absolute;
  top: 479px;
  width: 479px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .p {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 81px;
  left: 159px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 50px;
  width: 316px;
}

.product-page .text-wrapper-8 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 20px;
  left: 159px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.product-page .img {
  height: 126px;
  left: 0;
  position: absolute;
  top: 0;
  width: 133px;
}

.product-page .group-5 {
  height: 111px;
  left: 725px;
  position: absolute;
  top: 90px;
  width: 459px;
}

.product-page .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 44px;
  left: 139px;
  position: absolute;
  top: 62px;
  width: 316px;
}

.product-page .text-wrapper-9 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 20px;
  left: 139px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 22px;
  white-space: nowrap;
}

.product-page .frame-2 {
  height: 111px;
  left: 0;
  position: absolute;
  top: 0;
  width: 115px;
}

.product-page .group-6 {
  height: 97px;
  left: 725px;
  position: absolute;
  top: 416px;
  width: 412px;
}

.product-page .text-wrapper-10 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 20px;
  left: 127px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.product-page .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 57px;
  left: 127px;
  position: absolute;
  top: 47px;
  width: 281px;
}

.product-page .frame-3 {
  height: 91px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.product-page .group-7 {
  height: 107px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 470px;
}

.product-page .text-wrapper-11 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 24px;
  font-weight: bold;
  height: 20px;
  left: 150px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.product-page .simplifying-adoption {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 68px;
  left: 150px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 50px;
  width: 316px;
}

.product-page .frame-4 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 0;
  width: 116px;
}

.product-page .vector {
  height: 597px;
  left: 14px;
  position: absolute;
  top: 2px;
  width: 1194px;
}

.product-page .group-8 {
  height: 336px;
  left: 105px;
  position: absolute;
  top: 708px;
  width: 1240px;
}

.product-page .group-9 {
  height: 336px;
  left: 0;
  position: absolute;
  top: 0;
  width: 599px;
}

.product-page .group-10 {
  height: 63px;
  left: 0;
  position: absolute;
  top: 0;
  width: 601px;
}

.product-page .overlap-group-3 {
  background-color: #79c6fd;
  border-radius: 13px;
  height: 63px;
  position: relative;
  width: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-12 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.product-page .group-11 {
  height: 255px;
  left: 0;
  position: absolute;
  top: 81px;
  width: 601px;
}

.product-page .flexcontainer-wrapper {
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 4px 10px #00000026;
  height: 255px;
  width: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 197px;
  position: relative;
  width: 514px;
}

.product-page .text-2 {
  align-self: stretch;
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.product-page .text-wrapper-13 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.product-page .group-12 {
  height: 336px;
  left: 641px;
  position: absolute;
  top: 0;
  width: 599px;
}

.product-page .overlap-group-4 {
  background-color: #79c6fe;
  border-radius: 13px;
  height: 63px;
  position: relative;
  width: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-14 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  white-space: nowrap;
  text-align: center; 
}

.product-page .flexcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 218px;
  position: relative;
  width: 527px;
}

.product-page .group-13 {
  height: 327px;
  left: 105px;
  position: absolute;
  top: 1930px;
  width: 1234px;
}

.product-page .group-14 {
  height: 63px;
  left: 312px;
  position: absolute;
  top: 0;
  width: 601px;
}

.product-page .vision-mission {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  white-space: nowrap;
}

.product-page .group-15 {
  height: 229px;
  left: 0;
  position: absolute;
  top: 98px;
  width: 1234px;
}

.product-page .group-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 13px;
  height: 229px;
  left: 635px;
  position: absolute;
  top: 0;
  width: 599px;
}

.product-page .group-16 {
  height: 140px;
  left: 51px;
  position: relative;
  top: 48px;
  width: 470px;
}

.product-page .frame-5 {
  height: 140px;
  left: 0;
  position: absolute;
  top: 0;
  width: 134px;
}

.product-page .text-wrapper-15 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 32px;
  font-weight: 800;
  height: 20px;
  left: 194px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.product-page .text-wrapper-16 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 81px;
  left: 194px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 52px;
  width: 272px;
}

.product-page .group-17 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #595959;
  border-radius: 13px;
  height: 229px;
  left: 0;
  position: absolute;
  top: 0;
  width: 599px;
}

.product-page .group-18 {
  height: 137px;
  left: 52px;
  position: relative;
  top: 50px;
  width: 478px;
}

.product-page .frame-6 {
  height: 137px;
  left: 0;
  position: absolute;
  top: 0;
  width: 144px;
}

.product-page .text-wrapper-17 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 32px;
  font-weight: 800;
  height: 20px;
  left: 198px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.product-page .text-wrapper-18 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 81px;
  left: 198px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 50px;
  width: 276px;
}

.product-page .group-19 {
  height: 384px;
  left: 105px;
  position: absolute;
  top: 2338px;
  width: 1240px;
}

.product-page .overlap-3 {
  height: 384px;
  position: relative;
  width: 1242px;
}

.product-page .group-20 {
  height: 384px;
  left: 0;
  position: absolute;
  top: 0;
  width: 601px;
}

.product-page .text-wrapper-19 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
}

.product-page .FDA-compliance {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 283px;
  left: 11px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 101px;
  width: 400px;
}

.product-page .group-21 {
  height: 320px;
  left: 641px;
  position: absolute;
  top: 0;
  width: 601px;
}

.product-page .overlap-group-5 {
  background-color: #67a3ec;
  border-radius: 13px;
  height: 63px;
  position: relative;
  width: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-20 {
  color: #ffffff;
  font-family: "FiraGO", "Fira Sans";
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  white-space: nowrap;
}

.product-page .AI-driven-efficiency {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 142px;
  left: 193px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 170px;
  width: 400px;
}

.product-page .frame-7 {
  height: 257px;
  left: 434px;
  position: absolute;
  top: 101px;
  width: 369px;
}

.product-page .logo {
  height: 70px;
  left: 102px;
  position: absolute;
  top: 86px;
  width: 225px;
}

.product-page .group-22 {
  height: 464px;
  left: 102px;
  position: absolute;
  top: 197px;
  width: 1228px;
}

.product-page .overlap-4 {
  height: 464px;
  position: relative;
}

.product-page .group-23 {
  height: 385px;
  left: 0;
  position: absolute;
  top: 79px;
  width: 726px;
}

.product-page .overlap-group-6 {
  height: 223px;
  left: 0;
  position: absolute;
  top: 0;
  width: 720px;
}

.product-page .revolutionizing {
  color: transparent;
  font-family: "FiraGO", "Fira Sans";
  font-size: 48.5px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 0;
  width: 720px;
}

.product-page .text-wrapper-21 {
  color: #636363;
  font-weight: 800;
}

.product-page .text-wrapper-22 {
  color: #636363;
  font-family: "FiraGO", "Fira Sans";
  font-weight: 600;
}

.product-page .text-wrapper-23 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 46.7px;
}

.product-page .navigate-regulatory {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 32px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 147px;
}

.product-page .text-wrapper-24 {
  color: #595959;
  font-family: "FiraGO", "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  height: 120px;
  left: 6px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 265px;
  width: 448px;
}

.product-page .rectangle {
  background-color: #f97267;
  height: 4px;
  left: 6px;
  position: absolute;
  top: 246px;
  width: 265px;
}

.product-page .group-24 {
  height: 437px;
  left: 700px;
  position: absolute;
  top: 0;
  width: 528px;
}

.product-page .group-25 {
  height: 162px;
  position: absolute;
  top: 2900px;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .overlap-5 {
  background-color: #595959;
  height: 162px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page .text-wrapper-25 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.product-page .group-26 {
  height: 26px;
  left: 358px;
  position: absolute;
  top: 87px;
  width: 213px;
}

.product-page .text-wrapper-26 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 189px;
}

.product-page .vector-2 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.product-page .group-27 {
  height: 22px;
  left: 937px;
  position: absolute;
  top: 90px;
  width: 149px;
}

.product-page .text-wrapper-27 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 118px;
}

.product-page .vector-3 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.product-page .group-28 {
  height: 23px;
  left: 608px;
  position: absolute;
  top: 88px;
  width: 118px;
}

.product-page .text-wrapper-28 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 91px;
}

.product-page .group-29 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.product-page .group-30 {
  height: 22px;
  left: 771px;
  position: absolute;
  top: 90px;
  width: 133px;
}

.product-page .text-wrapper-29 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  height: 22px;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 99px;
}

.product-page .vector-4 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.product-page .rectangle-2 {
  background-color: #ffffff;
  height: 18px;
  left: 588px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.product-page .rectangle-3 {
  background-color: #ffffff;
  height: 18px;
  left: 746px;
  position: absolute;
  top: 91px;
  width: 1px;
}

.product-page .rectangle-4 {
  background-color: #ffffff;
  height: 18px;
  left: 920px;
  position: absolute;
  top: 91px;
  width: 1px;
}
