body {
  background-color: #f5f9fc;
  margin: 0;
  padding: 0;
}

.product-page {
  background-color: #f5f9fc;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.product-page .div {
  width: 1440px;
  height: 3062px;
  position: relative;
}

.product-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 888px;
  height: 87px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 72px;
  left: 475px;
}

.product-page .navbar {
  align-items: center;
  height: 35px;
  display: flex;
}

.product-page .text-wrapper {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.product-page .text-wrapper-2, .product-page .text-wrapper-3, .product-page .text-wrapper-4 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.product-page .overlap-group-wrapper {
  width: 20%;
  height: 35px;
}

.product-page .overlap-group {
  border: 1px solid #53b3f8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  display: flex;
}

.product-page .text-wrapper-5 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.product-page .group-2 {
  width: 1208px;
  height: 718px;
  position: absolute;
  top: 1135px;
  left: 123px;
}

.product-page .div-wrapper {
  width: 601px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 294px;
}

.product-page .overlap-group-2 {
  background-color: #41cbe0;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 63px;
  display: flex;
  position: relative;
}

.product-page .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
}

.product-page .overlap-wrapper {
  width: 1208px;
  height: 610px;
  position: absolute;
  top: 108px;
  left: 0;
}

.product-page .overlap-2 {
  height: 610px;
  position: relative;
}

.product-page .group-3 {
  width: 489px;
  height: 113px;
  position: absolute;
  top: 237px;
  left: 149px;
}

.product-page .text-wrapper-7 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 9px;
  left: 169px;
}

.product-page .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 316px;
  height: 57px;
  display: flex;
  position: absolute;
  top: 50px;
  left: 169px;
}

.product-page .text {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}

.product-page .span {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.product-page .span-wrapper {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  margin-bottom: -3px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}

.product-page .frame-11 {
  width: 146px;
  height: 113px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-4 {
  justify-content: center;
  align-items: center;
  width: 479px;
  height: 131px;
  display: flex;
  position: absolute;
  top: 479px;
  left: 0;
}

.product-page .p {
  color: #595959;
  letter-spacing: 0;
  width: 316px;
  height: 81px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 50px;
  left: 159px;
}

.product-page .text-wrapper-8 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 9px;
  left: 159px;
}

.product-page .img {
  width: 133px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-5 {
  width: 459px;
  height: 111px;
  position: absolute;
  top: 90px;
  left: 725px;
}

.product-page .flexcontainer-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 316px;
  height: 44px;
  display: flex;
  position: absolute;
  top: 62px;
  left: 139px;
}

.product-page .text-wrapper-9 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 22px;
  left: 139px;
}

.product-page .frame-2 {
  width: 115px;
  height: 111px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-6 {
  width: 412px;
  height: 97px;
  position: absolute;
  top: 416px;
  left: 725px;
}

.product-page .text-wrapper-10 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 10px;
  left: 127px;
}

.product-page .flexcontainer-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 281px;
  height: 57px;
  display: flex;
  position: absolute;
  top: 47px;
  left: 127px;
}

.product-page .frame-3 {
  width: 102px;
  height: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-7 {
  width: 470px;
  height: 107px;
  position: absolute;
  top: 0;
  left: 9px;
}

.product-page .text-wrapper-11 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 7px;
  left: 150px;
}

.product-page .simplifying-adoption {
  color: #595959;
  letter-spacing: 0;
  width: 316px;
  height: 68px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 50px;
  left: 150px;
}

.product-page .frame-4 {
  width: 116px;
  height: 101px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .vector {
  width: 1194px;
  height: 597px;
  position: absolute;
  top: 2px;
  left: 14px;
}

.product-page .group-8 {
  width: 1240px;
  height: 336px;
  position: absolute;
  top: 708px;
  left: 105px;
}

.product-page .group-9 {
  width: 599px;
  height: 336px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-10 {
  width: 601px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .overlap-group-3 {
  background-color: #79c6fd;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 63px;
  display: flex;
  position: relative;
}

.product-page .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
}

.product-page .group-11 {
  width: 601px;
  height: 255px;
  position: absolute;
  top: 81px;
  left: 0;
}

.product-page .flexcontainer-wrapper {
  background-color: #fff;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 255px;
  display: flex;
  box-shadow: 0 4px 10px #00000026;
}

.product-page .flexcontainer-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 514px;
  height: 197px;
  display: flex;
  position: relative;
}

.product-page .text-2 {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}

.product-page .text-wrapper-13 {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.product-page .group-12 {
  width: 599px;
  height: 336px;
  position: absolute;
  top: 0;
  left: 641px;
}

.product-page .overlap-group-4 {
  background-color: #79c6fe;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 63px;
  display: flex;
  position: relative;
}

.product-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
}

.product-page .flexcontainer-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 527px;
  height: 218px;
  display: flex;
  position: relative;
}

.product-page .group-13 {
  width: 1234px;
  height: 327px;
  position: absolute;
  top: 1930px;
  left: 105px;
}

.product-page .group-14 {
  width: 601px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 312px;
}

.product-page .vision-mission {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
}

.product-page .group-15 {
  width: 1234px;
  height: 229px;
  position: absolute;
  top: 98px;
  left: 0;
}

.product-page .group-wrapper {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 13px;
  width: 599px;
  height: 229px;
  position: absolute;
  top: 0;
  left: 635px;
}

.product-page .group-16 {
  width: 470px;
  height: 140px;
  position: relative;
  top: 48px;
  left: 51px;
}

.product-page .frame-5 {
  width: 134px;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .text-wrapper-15 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 32px;
  font-weight: 800;
  line-height: 20px;
  position: absolute;
  top: 7px;
  left: 194px;
}

.product-page .text-wrapper-16 {
  color: #595959;
  letter-spacing: 0;
  width: 272px;
  height: 81px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 52px;
  left: 194px;
}

.product-page .group-17 {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 13px;
  width: 599px;
  height: 229px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-18 {
  width: 478px;
  height: 137px;
  position: relative;
  top: 50px;
  left: 52px;
}

.product-page .frame-6 {
  width: 144px;
  height: 137px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .text-wrapper-17 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 32px;
  font-weight: 800;
  line-height: 20px;
  position: absolute;
  top: 5px;
  left: 198px;
}

.product-page .text-wrapper-18 {
  color: #595959;
  letter-spacing: 0;
  width: 276px;
  height: 81px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 50px;
  left: 198px;
}

.product-page .group-19 {
  width: 1240px;
  height: 384px;
  position: absolute;
  top: 2338px;
  left: 105px;
}

.product-page .overlap-3 {
  width: 1242px;
  height: 384px;
  position: relative;
}

.product-page .group-20 {
  width: 601px;
  height: 384px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .text-wrapper-19 {
  color: #fff;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
}

.product-page .FDA-compliance {
  color: #595959;
  letter-spacing: 0;
  width: 400px;
  height: 283px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 101px;
  left: 11px;
}

.product-page .group-21 {
  width: 601px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 641px;
}

.product-page .overlap-group-5 {
  background-color: #67a3ec;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 63px;
  display: flex;
  position: relative;
}

.product-page .text-wrapper-20 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
}

.product-page .AI-driven-efficiency {
  color: #595959;
  letter-spacing: 0;
  width: 400px;
  height: 142px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 170px;
  left: 193px;
}

.product-page .frame-7 {
  width: 369px;
  height: 257px;
  position: absolute;
  top: 101px;
  left: 434px;
}

.product-page .logo {
  width: 225px;
  height: 70px;
  position: absolute;
  top: 86px;
  left: 102px;
}

.product-page .group-22 {
  width: 1228px;
  height: 464px;
  position: absolute;
  top: 197px;
  left: 102px;
}

.product-page .overlap-4 {
  height: 464px;
  position: relative;
}

.product-page .group-23 {
  width: 726px;
  height: 385px;
  position: absolute;
  top: 79px;
  left: 0;
}

.product-page .overlap-group-6 {
  width: 720px;
  height: 223px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .revolutionizing {
  color: #0000;
  letter-spacing: 0;
  width: 720px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: 400;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .text-wrapper-21 {
  color: #636363;
  font-weight: 800;
}

.product-page .text-wrapper-22 {
  color: #636363;
  font-family: FiraGO, Fira Sans;
  font-weight: 600;
}

.product-page .text-wrapper-23 {
  color: #595959;
  font-family: FiraGO, Fira Sans;
  font-size: 46.7px;
}

.product-page .navigate-regulatory {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 147px;
  left: 6px;
}

.product-page .text-wrapper-24 {
  color: #595959;
  letter-spacing: 0;
  width: 448px;
  height: 120px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 265px;
  left: 6px;
}

.product-page .rectangle {
  background-color: #f97267;
  width: 265px;
  height: 4px;
  position: absolute;
  top: 246px;
  left: 6px;
}

.product-page .group-24 {
  width: 528px;
  height: 437px;
  position: absolute;
  top: 0;
  left: 700px;
}

.product-page .group-25 {
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 162px;
  display: flex;
  position: absolute;
  top: 2900px;
}

.product-page .overlap-5 {
  background-color: #595959;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 162px;
  display: flex;
  position: relative;
}

.product-page .text-wrapper-25 {
  color: #fff;
  letter-spacing: 0;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.product-page .group-26 {
  width: 213px;
  height: 26px;
  position: absolute;
  top: 87px;
  left: 358px;
}

.product-page .text-wrapper-26 {
  color: #fff;
  letter-spacing: 0;
  width: 189px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 22px;
}

.product-page .vector-2 {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-27 {
  width: 149px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 937px;
}

.product-page .text-wrapper-27 {
  color: #fff;
  letter-spacing: 0;
  width: 118px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 28px;
}

.product-page .vector-3 {
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-28 {
  width: 118px;
  height: 23px;
  position: absolute;
  top: 88px;
  left: 608px;
}

.product-page .text-wrapper-28 {
  color: #fff;
  letter-spacing: 0;
  width: 91px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 25px;
}

.product-page .group-29 {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-page .group-30 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 771px;
}

.product-page .text-wrapper-29 {
  color: #fff;
  letter-spacing: 0;
  width: 99px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 32px;
}

.product-page .vector-4 {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
}

.product-page .rectangle-2 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 588px;
}

.product-page .rectangle-3 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 746px;
}

.product-page .rectangle-4 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 920px;
}

.services-page {
  background-color: #f5f9fc;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.services-page .div {
  width: 1440px;
  height: 1976px;
  position: relative;
}

.services-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 888px;
  height: 87px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 72px;
  left: 475px;
}

.services-page .navbar {
  align-items: center;
  height: 35px;
  display: flex;
}

.services-page .text-wrapper {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.services-page .text-wrapper-2 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.services-page .text-wrapper-3, .services-page .text-wrapper-4 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.services-page .overlap-group-wrapper {
  width: 20%;
  height: 35px;
}

.services-page .overlap-group {
  border: 1px solid #53b3f8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  display: flex;
}

.services-page .text-wrapper-5 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.services-page .overlap-2 {
  width: 1288px;
  height: 459px;
  position: absolute;
  top: 205px;
  left: 102px;
}

.services-page .text {
  color: #000;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 71.1px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 219px;
  left: 549px;
}

.services-page .group-2 {
  width: 1288px;
  height: 459px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .group-3 {
  width: 572px;
  height: 251px;
  position: absolute;
  top: 116px;
  left: 0;
}

.services-page .text-wrapper-6 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 373px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .overlap-group-2 {
  width: 568px;
  height: 176px;
  position: absolute;
  top: 75px;
  left: 0;
}

.services-page .unlock-the-potential {
  color: #595959;
  letter-spacing: 0;
  width: 568px;
  height: 176px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 40px;
  left: 0;
}

.services-page .rectangle {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 6px;
  left: 0;
}

.services-page .mockup-kiu {
  object-fit: cover;
  width: 674px;
  height: 459px;
  position: absolute;
  top: 0;
  left: 614px;
}

.services-page .logo {
  width: 225px;
  height: 70px;
  position: absolute;
  top: 86px;
  left: 102px;
}

.services-page .group-4 {
  width: 1245px;
  height: 474px;
  position: absolute;
  top: 1266px;
  left: 102px;
}

.services-page .group-5 {
  width: 1245px;
  height: 383px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .project-management {
  color: #595959;
  letter-spacing: 0;
  width: 558px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .rectangle-2 {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 141px;
  left: 0;
}

.services-page .group-6 {
  width: 1243px;
  height: 198px;
  position: absolute;
  top: 185px;
  left: 0;
}

.services-page .div-wrapper {
  width: 394px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .overlap-group-3 {
  background-color: #41cbe0;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 390px;
  height: 193px;
  display: flex;
  position: relative;
}

.services-page .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  height: 50px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 40px;
  left: 40px;
}

.services-page .p {
  color: #fff;
  letter-spacing: 0;
  width: 310px;
  height: 123px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 80px;
  left: 40px;
}

.services-page .overlap-wrapper {
  width: 394px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 426px;
}

.services-page .overlap-3 {
  background-color: #79c6fe;
  border-radius: 13px;
  width: 390px;
  height: 193px;
  position: relative;
}

.services-page .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  width: 270px;
  height: 49px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 40px;
  left: 41px;
}

.services-page .text-wrapper-9 {
  color: #fff;
  letter-spacing: 0;
  width: 310px;
  height: 123px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 80px;
  left: 41px;
}

.services-page .group-7 {
  width: 394px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 853px;
}

.services-page .overlap-4 {
  width: 390px;
  height: 198px;
  position: relative;
}

.services-page .rectangle-3 {
  background-color: #67a3ec;
  border: 0;
  border-radius: 13px;
  width: 390px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .text-wrapper-10 {
  color: #fff;
  letter-spacing: 0;
  width: 270px;
  height: 42px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: 40px;
  left: 48px;
}

.services-page .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  width: 310px;
  height: 116px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 80px;
  left: 48px;
}

.services-page .text-wrapper-12 {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  width: 571px;
  height: 63px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 422px;
  left: 339px;
}

.services-page .group-8 {
  width: 1277px;
  height: 473px;
  position: absolute;
  top: 736px;
  left: 57px;
}

.services-page .group-9 {
  width: 556px;
  height: 325px;
  position: absolute;
  top: 73px;
  left: 725px;
}

.services-page .text-wrapper-13 {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .QA-validation {
  color: #595959;
  letter-spacing: 0;
  width: 544px;
  height: 232px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 110px;
  left: 8px;
}

.services-page .rectangle-4 {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 77px;
  left: 8px;
}

.services-page .img {
  object-fit: cover;
  width: 695px;
  height: 473px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .group-10 {
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 162px;
  display: flex;
  position: absolute;
  top: 1814px;
}

.services-page .overlap-5 {
  background-color: #595959;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 162px;
  display: flex;
  position: relative;
}

.services-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.services-page .group-11 {
  width: 213px;
  height: 26px;
  position: absolute;
  top: 87px;
  left: 358px;
}

.services-page .text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  width: 189px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 22px;
}

.services-page .vector {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .group-12 {
  width: 149px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 937px;
}

.services-page .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  width: 118px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 28px;
}

.services-page .vector-2 {
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .group-13 {
  width: 118px;
  height: 23px;
  position: absolute;
  top: 88px;
  left: 608px;
}

.services-page .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  width: 91px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 25px;
}

.services-page .group-14 {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.services-page .group-15 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 771px;
}

.services-page .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  width: 99px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 32px;
}

.services-page .vector-3 {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
}

.services-page .rectangle-5 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 588px;
}

.services-page .rectangle-6 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 746px;
}

.services-page .rectangle-7 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 92px;
  left: 920px;
}

.about-us-page {
  background-color: #f5f9fc;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.about-us-page .div {
  width: 1440px;
  height: 1476px;
  position: relative;
}

.about-us-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 888px;
  height: 87px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 72px;
  left: 475px;
}

.about-us-page .navbar {
  align-items: center;
  height: 35px;
  display: flex;
}

.about-us-page .text-wrapper, .about-us-page .text-wrapper-2 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.about-us-page .text-wrapper-3 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.about-us-page .text-wrapper-4 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.about-us-page .overlap-group-wrapper {
  width: 20%;
  height: 35px;
}

.about-us-page .overlap-group {
  border: 1px solid #53b3f8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  display: flex;
}

.about-us-page .text-wrapper-5 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.about-us-page .overlap-2 {
  width: 845px;
  height: 347px;
  position: absolute;
  top: 278px;
  left: 112px;
}

.about-us-page .text {
  color: #000;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 71.1px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 146px;
  left: 539px;
}

.about-us-page .group-2 {
  width: 845px;
  height: 347px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .group-3 {
  width: 486px;
  height: 295px;
  position: absolute;
  top: 0;
  left: 363px;
}

.about-us-page .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 482px;
  height: 177px;
  display: flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.about-us-page .span-wrapper {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}

.about-us-page .span {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.about-us-page .text-wrapper-6 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 345px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .rectangle {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 84px;
  left: 0;
}

.about-us-page .img {
  width: 102px;
  height: 20px;
  position: absolute;
  top: 327px;
  left: 363px;
}

.about-us-page .group-4 {
  background-image: url("clip-path-group-1.89488de0.png");
  background-size: 100% 100%;
  width: 297px;
  height: 297px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .logo {
  width: 225px;
  height: 70px;
  position: absolute;
  top: 86px;
  left: 102px;
}

.about-us-page .group-5 {
  width: 866px;
  height: 389px;
  position: absolute;
  top: 728px;
  left: 454px;
}

.about-us-page .group-6 {
  width: 544px;
  height: 329px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .flexcontainer-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 510px;
  height: 209px;
  display: flex;
  position: absolute;
  top: 120px;
  left: 0;
}

.about-us-page .p {
  color: #595959;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}

.about-us-page .text-wrapper-7 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 308px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 232px;
}

.about-us-page .rectangle-2 {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 85px;
  left: 437px;
}

.about-us-page .group-7 {
  width: 102px;
  height: 20px;
  position: absolute;
  top: 369px;
  left: 408px;
}

.about-us-page .group-8 {
  background-image: url("clip-path-group.15a21f47.png");
  background-size: 100% 100%;
  width: 297px;
  height: 297px;
  position: absolute;
  top: 16px;
  left: 569px;
}

.about-us-page .overlap-3 {
  width: 1507px;
  height: 2076px;
  position: absolute;
  top: 826px;
  left: -5866px;
}

.about-us-page .rectangle-3 {
  width: 1468px;
  height: 1492px;
  position: absolute;
  top: 34px;
  left: 39px;
}

.about-us-page .rectangle-4 {
  width: 1468px;
  height: 2076px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .overlap-wrapper {
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 162px;
  display: flex;
  position: absolute;
  top: 1314px;
}

.about-us-page .overlap-4 {
  background-color: #595959;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 162px;
  display: flex;
  position: relative;
}

.about-us-page .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.about-us-page .group-9 {
  width: 213px;
  height: 26px;
  position: absolute;
  top: 87px;
  left: 358px;
}

.about-us-page .text-wrapper-9 {
  color: #fff;
  letter-spacing: 0;
  width: 189px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 22px;
}

.about-us-page .vector {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .group-10 {
  width: 149px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 937px;
}

.about-us-page .text-wrapper-10 {
  color: #fff;
  letter-spacing: 0;
  width: 118px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 28px;
}

.about-us-page .vector-2 {
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .group-11 {
  width: 118px;
  height: 23px;
  position: absolute;
  top: 88px;
  left: 608px;
}

.about-us-page .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  width: 91px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 25px;
}

.about-us-page .group-12 {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .group-13 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 771px;
}

.about-us-page .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  width: 99px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 32px;
}

.about-us-page .vector-3 {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
}

.about-us-page .rectangle-5 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 588px;
}

.about-us-page .rectangle-6 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 746px;
}

.about-us-page .rectangle-7 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 920px;
}

.for-investors-page {
  background-color: #f5f9fc;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.for-investors-page .div {
  background-color: #fff;
  width: 1440px;
  height: 2723px;
  position: relative;
}

.for-investors-page .overlap {
  background-color: #f5f9fc;
  width: 1440px;
  height: 2561px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 888px;
  height: 87px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 72px;
  left: 475px;
}

.for-investors-page .navbar {
  align-items: center;
  height: 35px;
  display: flex;
}

.for-investors-page .text-wrapper, .for-investors-page .text-wrapper-2, .for-investors-page .text-wrapper-3 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.for-investors-page .text-wrapper-4 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.for-investors-page .overlap-group-wrapper {
  width: 20%;
  height: 35px;
}

.for-investors-page .overlap-group {
  border: 1px solid #53b3f8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  display: flex;
}

.for-investors-page .text-wrapper-5 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.for-investors-page .logo {
  width: 225px;
  height: 70px;
  position: absolute;
  top: 86px;
  left: 102px;
}

.for-investors-page .overlap-2 {
  width: 1261px;
  height: 714px;
  position: absolute;
  top: 553px;
  left: 90px;
}

.for-investors-page .text {
  color: #000;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 71.1px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 0;
  left: 561px;
}

.for-investors-page .group-2 {
  width: 1261px;
  height: 713px;
  position: absolute;
  top: 1px;
  left: 0;
}

.for-investors-page .group-wrapper {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 15px;
  width: 1261px;
  height: 549px;
  position: absolute;
  top: 164px;
  left: 0;
}

.for-investors-page .group-3 {
  width: 1143px;
  height: 485px;
  position: relative;
  top: 30px;
  left: 77px;
}

.for-investors-page .overlap-3 {
  width: 1105px;
  height: 412px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .frame {
  width: 1073px;
  height: 393px;
  position: absolute;
  top: 10px;
  left: 28px;
  overflow: hidden;
}

.for-investors-page .vector {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 39px;
}

.for-investors-page .overlap-4 {
  width: 1007px;
  height: 347px;
  position: absolute;
  top: 45px;
  left: 39px;
}

.for-investors-page .overlap-group-2 {
  width: 1007px;
  height: 343px;
  position: absolute;
  top: 4px;
  left: 0;
}

.for-investors-page .img {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 29px;
  left: 0;
}

.for-investors-page .vector-2 {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 107px;
  left: 0;
}

.for-investors-page .vector-3 {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 185px;
  left: 0;
}

.for-investors-page .vector-4 {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 264px;
  left: 0;
}

.for-investors-page .vector-5 {
  width: 60px;
  height: 343px;
  position: absolute;
  top: 0;
  left: 831px;
}

.for-investors-page .vector-6 {
  width: 60px;
  height: 153px;
  position: absolute;
  top: 190px;
  left: 563px;
}

.for-investors-page .vector-7 {
  width: 60px;
  height: 52px;
  position: absolute;
  top: 291px;
  left: 296px;
}

.for-investors-page .vector-8 {
  width: 60px;
  height: 208px;
  position: absolute;
  top: 135px;
  left: 907px;
}

.for-investors-page .vector-9 {
  width: 60px;
  height: 88px;
  position: absolute;
  top: 255px;
  left: 640px;
}

.for-investors-page .vector-10 {
  width: 60px;
  height: 26px;
  position: absolute;
  top: 317px;
  left: 372px;
}

.for-investors-page .vector-11 {
  width: 806px;
  height: 304px;
  position: absolute;
  top: 15px;
  left: 95px;
}

.for-investors-page .text-wrapper-6 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 214px;
  left: 343px;
}

.for-investors-page .text-wrapper-7 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 136px;
  left: 519px;
}

.for-investors-page .vector-12 {
  width: 11px;
  height: 26px;
  position: absolute;
  top: 293px;
  left: 85px;
}

.for-investors-page .vector-13 {
  width: 11px;
  height: 26px;
  position: absolute;
  top: 239px;
  left: 352px;
}

.for-investors-page .vector-14 {
  width: 11px;
  height: 26px;
  position: absolute;
  top: 160px;
  left: 533px;
}

.for-investors-page .vector-15 {
  width: 60px;
  height: 8px;
  position: absolute;
  top: 340px;
  left: 28px;
}

.for-investors-page .vector-16 {
  width: 60px;
  height: 4px;
  position: absolute;
  top: 343px;
  left: 105px;
}

.for-investors-page .text-wrapper-8 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 275px;
  left: 78px;
}

.for-investors-page .text-wrapper-9 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 914px;
}

.for-investors-page .vector-17 {
  object-fit: cover;
  width: 1007px;
  height: 1px;
  position: absolute;
  top: 392px;
  left: 39px;
}

.for-investors-page .vector-18 {
  width: 1073px;
  height: 393px;
  position: absolute;
  top: 117px;
  left: -11111px;
}

.for-investors-page .text-wrapper-10 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 392px;
  left: 1083px;
}

.for-investors-page .text-wrapper-11 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 329px;
  left: 1083px;
}

.for-investors-page .text-wrapper-12 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 311px;
  left: 10px;
}

.for-investors-page .text-wrapper-13 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 235px;
  left: 0;
}

.for-investors-page .text-wrapper-14 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 157px;
  left: 0;
}

.for-investors-page .text-wrapper-15 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 79px;
  left: 0;
}

.for-investors-page .text-wrapper-16 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .text-wrapper-17 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 271px;
  left: 1083px;
}

.for-investors-page .text-wrapper-18 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 201px;
  left: 1083px;
}

.for-investors-page .text-wrapper-19 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 126px;
  left: 1083px;
}

.for-investors-page .text-wrapper-20 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 59px;
  left: 1083px;
}

.for-investors-page .text-wrapper-21 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 1083px;
}

.for-investors-page .vector-19 {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 471px;
  left: 185px;
}

.for-investors-page .vector-20 {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 471px;
  left: 400px;
}

.for-investors-page .vector-21 {
  width: 60px;
  height: 5px;
  position: absolute;
  top: 474px;
  left: 751px;
}

.for-investors-page .text-wrapper-22 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 414px;
  left: 142px;
}

.for-investors-page .text-wrapper-23 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 414px;
  left: 411px;
}

.for-investors-page .text-wrapper-24 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 414px;
  left: 680px;
}

.for-investors-page .text-wrapper-25 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 414px;
  left: 944px;
}

.for-investors-page .text-wrapper-26 {
  color: #707070;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 466px;
  left: 252px;
}

.for-investors-page .text-wrapper-27 {
  color: #707070;
  letter-spacing: 0;
  text-align: center;
  width: 258px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 466px;
  left: 462px;
}

.for-investors-page .text-wrapper-28 {
  color: #707070;
  letter-spacing: 0;
  text-align: center;
  width: 130px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 466px;
  left: 814px;
}

.for-investors-page .group-4 {
  width: 565px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 12px;
}

.for-investors-page .text-wrapper-29 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 561px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .text-wrapper-30 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 106px;
  left: 0;
}

.for-investors-page .rectangle {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 75px;
  left: 0;
}

.for-investors-page .group-5 {
  width: 1262px;
  height: 373px;
  position: absolute;
  top: 1289px;
  left: 103px;
}

.for-investors-page .group-6 {
  justify-content: center;
  align-items: center;
  width: 278px;
  height: 344px;
  display: flex;
  position: absolute;
  top: 29px;
  left: 0;
}

.for-investors-page .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 221px;
  height: 46px;
  display: flex;
  position: absolute;
  top: 0;
  left: 21px;
}

.for-investors-page .span-wrapper {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.for-investors-page .span {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
}

.for-investors-page .div-wrapper {
  background-color: #79c6fe;
  border-radius: 137px;
  width: 274px;
  height: 274px;
  position: absolute;
  top: 70px;
  left: 0;
}

.for-investors-page .text-wrapper-31 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 122px;
  font-family: FiraGO, Fira Sans;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 109px;
  left: 78px;
}

.for-investors-page .group-7 {
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 273px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 421px;
}

.for-investors-page .text-wrapper-32 {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 45px;
}

.for-investors-page .overlap-5 {
  width: 158px;
  height: 148px;
  position: absolute;
  top: 125px;
  left: 0;
}

.for-investors-page .ellipse {
  background-color: #79c6fe;
  border-radius: 88px;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .text-wrapper-33 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 125px;
  font-family: FiraGO, Fira Sans;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 51px;
  left: 33px;
}

.for-investors-page .group-8 {
  width: 202px;
  height: 298px;
  position: absolute;
  top: 42px;
  left: 722px;
}

.for-investors-page .text-wrapper-34 {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: FiraGO, Fira Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 25px;
}

.for-investors-page .overlap-6 {
  background-color: #79c6fe;
  border-radius: 99px;
  width: 198px;
  height: 198px;
  position: absolute;
  top: 100px;
  left: 0;
}

.for-investors-page .text-wrapper-35 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 113px;
  font-family: FiraGO, Fira Sans;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 68px;
  left: 44px;
}

.for-investors-page .group-9 {
  width: 291px;
  height: 315px;
  position: absolute;
  top: 0;
  left: 975px;
}

.for-investors-page .flexcontainer-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 287px;
  height: 93px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .overlap-7 {
  background-color: #79c6fe;
  border-radius: 74px;
  width: 148px;
  height: 148px;
  position: absolute;
  top: 167px;
  left: 64px;
}

.for-investors-page .text-wrapper-36 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 106px;
  font-family: FiraGO, Fira Sans;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 43px;
  left: 28px;
}

.for-investors-page .overlap-8 {
  width: 1158px;
  height: 376px;
  position: absolute;
  top: 1708px;
  left: 102px;
}

.for-investors-page .text-2 {
  color: #000;
  letter-spacing: 0;
  width: 312px;
  height: 125px;
  font-family: FiraGO, Fira Sans;
  font-size: 23.1px;
  font-weight: 500;
  line-height: 23.1px;
  position: absolute;
  top: 116px;
  left: 497px;
}

.for-investors-page .group-10 {
  width: 1158px;
  height: 376px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-11 {
  width: 375px;
  height: 348px;
  position: absolute;
  top: 28px;
  left: 791px;
}

.for-investors-page .overlap-group-3 {
  background-image: url("group-1000001776.fa5008b4.png");
  background-size: 100% 100%;
  width: 367px;
  height: 348px;
  position: relative;
}

.for-investors-page .text-wrapper-37 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 57px;
  height: 19px;
  font-family: FiraGO, Fira Sans;
  font-size: 28.3px;
  font-weight: 500;
  line-height: 17.7px;
  position: absolute;
  top: 151px;
  left: 63px;
}

.for-investors-page .text-wrapper-38 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 57px;
  height: 19px;
  font-family: FiraGO, Fira Sans;
  font-size: 28.3px;
  font-weight: 500;
  line-height: 17.7px;
  position: absolute;
  top: 242px;
  left: 215px;
}

.for-investors-page .text-wrapper-39 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 56px;
  height: 19px;
  font-family: FiraGO, Fira Sans;
  font-size: 28.3px;
  font-weight: 500;
  line-height: 17.7px;
  position: absolute;
  top: 117px;
  left: 248px;
}

.for-investors-page .text-wrapper-40 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 40px;
  height: 19px;
  font-family: FiraGO, Fira Sans;
  font-size: 28.3px;
  font-weight: 500;
  line-height: 17.7px;
  position: absolute;
  top: 35px;
  left: 184px;
}

.for-investors-page .group-12 {
  width: 637px;
  height: 365px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-13 {
  width: 319px;
  height: 147px;
  position: absolute;
  top: 210px;
  left: 0;
}

.for-investors-page .flexcontainer-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  width: 257px;
  height: 147px;
  display: flex;
  position: absolute;
  top: 9px;
  left: 60px;
}

.for-investors-page .p {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 19px;
  font-weight: 500;
  line-height: 19px;
  position: relative;
}

.for-investors-page .text-wrapper-41 {
  color: #595959;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 19px;
  font-weight: 500;
  line-height: 19px;
}

.for-investors-page .rectangle-2 {
  background-color: #faae6e;
  width: 47px;
  height: 13px;
  position: absolute;
  top: 9px;
  left: 0;
}

.for-investors-page .rectangle-3 {
  background-color: #f97267;
  width: 47px;
  height: 13px;
  position: absolute;
  top: 47px;
  left: 0;
}

.for-investors-page .rectangle-4 {
  background-color: #79c6fe;
  width: 47px;
  height: 13px;
  position: absolute;
  top: 86px;
  left: 0;
}

.for-investors-page .rectangle-5 {
  background-color: #4680f1;
  width: 47px;
  height: 13px;
  position: absolute;
  top: 124px;
  left: 0;
}

.for-investors-page .text-wrapper-42 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 553px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .we-are-seeking-for {
  color: #595959;
  letter-spacing: 0;
  width: 633px;
  height: 99px;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 102px;
  left: 0;
}

.for-investors-page .rectangle-6 {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 81px;
  left: 0;
}

.for-investors-page .overlap-wrapper {
  width: 1263px;
  height: 284px;
  position: absolute;
  top: 230px;
  left: 90px;
}

.for-investors-page .flexcontainer-wrapper {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 15px;
  width: 1261px;
  height: 284px;
}

.for-investors-page .flexcontainer-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 1129px;
  height: 233px;
  display: flex;
  position: relative;
  top: 23px;
  left: 58px;
}

.for-investors-page .text-3 {
  color: #595959;
  letter-spacing: 0;
  align-self: stretch;
  font-family: FiraGO, Fira Sans;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.for-investors-page .text-wrapper-43 {
  font-weight: bold;
}

.for-investors-page .text-wrapper-44 {
  font-family: FiraGO, Fira Sans;
  font-weight: 300;
}

.for-investors-page .group-14 {
  width: 1278px;
  height: 365px;
  position: absolute;
  top: 2150px;
  left: 86px;
}

.for-investors-page .overlap-9 {
  background-color: #fff;
  border-radius: 10.6px;
  width: 1268px;
  height: 365px;
  position: relative;
  box-shadow: 0 0 63.6px 31.8px #00000008;
}

.for-investors-page .frame-2 {
  background-image: url("rectangle-3841.c775bb63.png");
  background-position: 50%;
  background-size: cover;
  width: 520px;
  height: 337px;
  position: absolute;
  top: 14px;
  left: 16px;
  overflow: hidden;
}

.for-investors-page .overlap-group-4 {
  width: 315px;
  height: 313px;
  position: absolute;
  top: 464px;
  left: 300px;
}

.for-investors-page .ellipse-2 {
  background-color: #95c9ffb2;
  border-radius: 142.57px;
  width: 285px;
  height: 285px;
  position: absolute;
  top: 28px;
  left: 30px;
}

.for-investors-page .ellipse-3 {
  background-color: #1e81ce4c;
  border-radius: 73.14px;
  width: 146px;
  height: 146px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .overlap-10 {
  width: 305px;
  height: 34px;
  position: absolute;
  top: 229px;
  left: 57px;
}

.for-investors-page .img-2 {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-15 {
  width: 302px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 3px;
}

.for-investors-page .vector-22 {
  width: 15px;
  height: 19px;
  position: absolute;
  top: 1px;
  left: 0;
}

.for-investors-page .element-six-forks-rd {
  color: #fff;
  letter-spacing: 0;
  width: 259px;
  font-family: FiraGO, Fira Sans;
  font-size: 14.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 41px;
}

.for-investors-page .group-16 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 165px;
  left: 57px;
}

.for-investors-page .text-wrapper-45 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 14.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 44px;
}

.for-investors-page .group-17 {
  width: 238px;
  height: 22px;
  position: absolute;
  top: 194px;
  left: 57px;
}

.for-investors-page .text-wrapper-46 {
  color: #fff;
  letter-spacing: 0;
  width: 181px;
  font-family: FiraGO, Fira Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 21.2px;
  position: absolute;
  top: 46px;
  left: 57px;
}

.for-investors-page .text-wrapper-47 {
  color: #fff;
  letter-spacing: 0;
  width: 194px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 21.2px;
  position: absolute;
  top: 124px;
  left: 57px;
}

.for-investors-page .overlap-11 {
  background-color: #f97267;
  border: none;
  border-radius: 12px;
  width: 105px;
  height: 30px;
  position: absolute;
  top: 312px;
  left: 1110px;
}

.for-investors-page .text-wrapper-48 {
  color: #fff;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 36px;
}

.for-investors-page .overlap-12 {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 435px;
  height: 30px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 780px;
}

.for-investors-page .text-wrapper-49 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 64px;
  height: 12px;
  font-family: FiraGO, Fira Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 8px;
  left: 21px;
}

.for-investors-page .overlap-13 {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 435px;
  height: 30px;
  display: flex;
  position: absolute;
  top: 78px;
  left: 780px;
}

.for-investors-page .overlap-14 {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 435px;
  height: 30px;
  display: flex;
  position: absolute;
  top: 125px;
  left: 780px;
}

.for-investors-page .text-wrapper-50 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 91px;
  height: 12px;
  font-family: FiraGO, Fira Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 8px;
  left: 21px;
}

.for-investors-page .overlap-15 {
  align-items: left;
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 12px;
  justify-content: left;
  width: 435px;
  height: 125px;
  display: flex;
  position: absolute;
  top: 173px;
  left: 780px;
}

.for-investors-page .overlap-16 {
  width: 2680px;
  height: 3006px;
  position: absolute;
  top: 978px;
  left: -8814px;
}

.for-investors-page .rectangle-7 {
  width: 1626px;
  height: 2299px;
  position: absolute;
  top: 0;
  left: 1054px;
}

.for-investors-page .rectangle-8 {
  width: 1468px;
  height: 2076px;
  position: absolute;
  top: 930px;
  left: 0;
}

.for-investors-page .group-18 {
  width: 1442px;
  height: 162px;
  position: absolute;
  top: 2561px;
  left: 0;
}

.for-investors-page .overlap-17 {
  background-color: #595959;
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 162px;
  display: flex;
  position: relative;
}

.for-investors-page .text-wrapper-51 {
  color: #fff;
  letter-spacing: 0;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.for-investors-page .group-19 {
  width: 213px;
  height: 26px;
  position: absolute;
  top: 87px;
  left: 358px;
}

.for-investors-page .text-wrapper-52 {
  color: #fff;
  letter-spacing: 0;
  width: 189px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 22px;
}

.for-investors-page .vector-23 {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-20 {
  width: 149px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 937px;
}

.for-investors-page .text-wrapper-53 {
  color: #fff;
  letter-spacing: 0;
  width: 118px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 28px;
}

.for-investors-page .vector-24 {
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-21 {
  width: 118px;
  height: 23px;
  position: absolute;
  top: 88px;
  left: 608px;
}

.for-investors-page .text-wrapper-54 {
  color: #fff;
  letter-spacing: 0;
  width: 91px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 25px;
}

.for-investors-page .group-22 {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.for-investors-page .group-23 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 771px;
}

.for-investors-page .text-wrapper-55 {
  color: #fff;
  letter-spacing: 0;
  width: 99px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 32px;
}

.for-investors-page .vector-25 {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
}

.for-investors-page .rectangle-9 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 588px;
}

.for-investors-page .rectangle-10 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 746px;
}

.for-investors-page .rectangle-11 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 920px;
}

.for-investors-page .overlap-12 input, .for-investors-page .overlap-13 input, .for-investors-page .overlap-14 input, .for-investors-page .overlap-15 textarea {
  box-sizing: border-box;
  resize: none;
  background: #fffc;
  border: none;
  outline: none;
  width: 100%;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  font-family: FiraGO, Helvetica;
}

.for-investors-page .overlap-15 textarea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.for-investors-page .loading {
  z-index: 999;
  background-color: #ffffffe6;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

.for-investors-page .popup {
  z-index: 999;
  background-color: #fff;
  padding: 20px;
  transition: opacity .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

.for-investors-page .popup.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.for-investors-page .popup.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.contact-us-page {
  background-color: #f5f9fc;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.contact-us-page .div {
  background-color: #fff;
  width: 1440px;
  height: 1476px;
  position: relative;
}

.contact-us-page .overlap {
  background-image: url("rectangle-88.7345ff78.svg");
  background-size: 100% 100%;
  width: 1440px;
  height: 1314px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group {
  background-color: #4680f1;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 888px;
  height: 87px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 72px;
  left: 475px;
}

.contact-us-page .navbar {
  align-items: center;
  height: 35px;
  display: flex;
}

.contact-us-page .text-wrapper, .contact-us-page .text-wrapper-2, .contact-us-page .text-wrapper-3, .contact-us-page .text-wrapper-4 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.contact-us-page .overlap-group-wrapper {
  width: 20%;
  height: 35px;
}

.contact-us-page .overlap-group {
  border: 1px solid #53b3f8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  display: flex;
}

.contact-us-page .text-wrapper-5 {
  color: #fff1f1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 33px;
  font-family: FiraGO, Fira Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.contact-us-page .logo {
  width: 225px;
  height: 70px;
  position: absolute;
  top: 86px;
  left: 102px;
}

.contact-us-page .group-2 {
  width: 1268px;
  height: 890px;
  position: absolute;
  top: 268px;
  left: 89px;
}

.contact-us-page .overlap-wrapper {
  width: 1270px;
  height: 707px;
  position: absolute;
  top: 183px;
  left: 0;
}

.contact-us-page .overlap-2 {
  width: 1268px;
  height: 707px;
  position: relative;
}

.contact-us-page .text {
  color: #000;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 71.1px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 158px;
  left: 562px;
}

.contact-us-page .div-wrapper {
  width: 1268px;
  height: 707px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .overlap-3 {
  height: 707px;
  position: relative;
}

.contact-us-page .overlap-4 {
  border-radius: 10.6px;
  height: 707px;
  position: relative;
}

.contact-us-page .frame-wrapper {
  background-color: #fff;
  border-radius: 10.6px;
  width: 1268px;
  height: 707px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 63.6px 31.8px #00000008;
}

.contact-us-page .frame {
  background-image: url("rectangle-3841.c775bb63.png");
  background-position: 50%;
  background-size: cover;
  width: 520px;
  height: 686px;
  position: relative;
  top: 11px;
  left: 11px;
  overflow: hidden;
}

.contact-us-page .overlap-group-2 {
  width: 315px;
  height: 313px;
  position: absolute;
  top: 464px;
  left: 300px;
}

.contact-us-page .ellipse {
  background-color: #95c9ffb2;
  border-radius: 142.57px;
  width: 285px;
  height: 285px;
  position: absolute;
  top: 28px;
  left: 30px;
}

.contact-us-page .ellipse-2 {
  background-color: #1e81ce4c;
  border-radius: 73.14px;
  width: 146px;
  height: 146px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  font-family: FiraGO, Fira Sans;
  font-size: 29.7px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 41px;
  left: 42px;
}

.contact-us-page .group-3 {
  width: 359px;
  height: 197px;
  position: absolute;
  top: 161px;
  left: 42px;
}

.contact-us-page .carbon-location {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 157px;
  left: 0;
}

.contact-us-page .group-4 {
  width: 157px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .img {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 52px;
}

.contact-us-page .group-5 {
  width: 281px;
  height: 25px;
  position: absolute;
  top: 78px;
  left: 0;
}

.contact-us-page .element-six-forks-rd {
  color: #fff;
  letter-spacing: 0;
  width: 305px;
  font-family: FiraGO, Fira Sans;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 157px;
  left: 52px;
}

.contact-us-page .group-6 {
  width: 297px;
  height: 58px;
  position: absolute;
  top: 64px;
  left: 584px;
}

.contact-us-page .vector {
  width: 295px;
  height: 3px;
  position: absolute;
  top: 50px;
  left: 0;
}

.contact-us-page .vector-2 {
  width: 1px;
  height: 12px;
  position: absolute;
  top: 34px;
  left: 5px;
}

.contact-us-page .text-wrapper-8 {
  color: #8d8d8d;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.2px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group-7 {
  width: 297px;
  height: 58px;
  position: absolute;
  top: 170px;
  left: 584px;
}

.contact-us-page .group-8 {
  width: 299px;
  height: 58px;
  position: absolute;
  top: 64px;
  left: 920px;
}

.contact-us-page .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 12.7px;
  font-weight: 500;
  line-height: 21.2px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .text-wrapper-10 {
  color: #8d8d8d;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 14.8px;
  font-weight: 500;
  line-height: 21.2px;
  position: absolute;
  top: 25px;
  left: 0;
}

.contact-us-page .group-9 {
  width: 635px;
  height: 59px;
  position: absolute;
  top: 329px;
  left: 584px;
}

.contact-us-page .vector-3 {
  width: 631px;
  height: 3px;
  position: absolute;
  top: 65px;
  left: 0;
}

.contact-us-page .text-wrapper-11 {
  color: #8d8d8d;
  letter-spacing: 0;
  white-space: nowrap;
  width: 158px;
  font-family: FiraGO, Fira Sans;
  font-size: 14.8px;
  font-weight: 500;
  line-height: 21.2px;
  position: absolute;
  top: 27px;
  left: 0;
}

.contact-us-page .group-10 {
  background-color: #f97267;
  border: none;
  border-radius: 9px;
  width: 216px;
  height: 39px;
  position: absolute;
  top: 413px;
  left: 998px;
}

.contact-us-page .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 18.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 48px;
}

.contact-us-page .frame-2 {
  width: 120px;
  height: 23px;
  position: absolute;
  top: 622px;
  left: 67px;
}

.contact-us-page .group-11 {
  width: 377px;
  height: 123px;
  position: absolute;
  top: 0;
  left: 16px;
}

.contact-us-page .p {
  color: #707070;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: FiraGO, Fira Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 104px;
  left: 0;
}

.contact-us-page .text-wrapper-13 {
  color: #595959;
  letter-spacing: 0;
  white-space: nowrap;
  width: 373px;
  font-family: FiraGO, Fira Sans;
  font-size: 48.5px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .rectangle {
  background-color: #f97267;
  width: 73px;
  height: 4px;
  position: absolute;
  top: 75px;
  left: 0;
}

.contact-us-page .overlap-6 {
  width: 1548px;
  height: 2076px;
  position: absolute;
  top: 826px;
  left: -7578px;
}

.contact-us-page .rectangle-2 {
  width: 1468px;
  height: 1492px;
  position: absolute;
  top: 34px;
  left: 80px;
}

.contact-us-page .rectangle-3 {
  width: 1468px;
  height: 2076px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group-12 {
  width: 1442px;
  height: 162px;
  position: absolute;
  top: 1314px;
  left: 0;
}

.contact-us-page .overlap-7 {
  background-color: #595959;
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 162px;
  display: flex;
  position: relative;
}

.contact-us-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 14.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.contact-us-page .group-13 {
  width: 213px;
  height: 26px;
  position: absolute;
  top: 87px;
  left: 358px;
}

.contact-us-page .text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  width: 189px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 22px;
}

.contact-us-page .vector-4 {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group-14 {
  width: 149px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 937px;
}

.contact-us-page .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  width: 118px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 28px;
}

.contact-us-page .vector-5 {
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group-15 {
  width: 118px;
  height: 23px;
  position: absolute;
  top: 88px;
  left: 608px;
}

.contact-us-page .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  width: 91px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 25px;
}

.contact-us-page .group-16 {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-us-page .group-17 {
  width: 133px;
  height: 22px;
  position: absolute;
  top: 90px;
  left: 771px;
}

.contact-us-page .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  width: 99px;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 12.4px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 32px;
}

.contact-us-page .vector-6 {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
}

.contact-us-page .rectangle-4 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 588px;
}

.contact-us-page .rectangle-5 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 746px;
}

.contact-us-page .rectangle-6 {
  background-color: #fff;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 91px;
  left: 920px;
}

.contact-us-page .group-6 input, .contact-us-page .group-7 input, .contact-us-page .group-8 input, .contact-us-page .group-9 textarea {
  box-sizing: border-box;
  resize: none;
  border: none;
  outline: none;
  width: 98%;
  margin-top: 18px;
  padding: 10px;
  font-family: FiraGO, Helvetica;
}

.contact-us-page .loading {
  z-index: 999;
  background-color: #ffffffe6;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

.contact-us-page .popup {
  z-index: 999;
  background-color: #fff;
  padding: 20px;
  transition: opacity .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

.contact-us-page .popup.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.contact-us-page .popup.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/*# sourceMappingURL=index.74f6e54b.css.map */
